body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: IRANSans, sans-serif !important;
}

body {
  background: #fff !important;
}

.swal2-content,
.swal2-title,
.swal2-confirm,
.swal2-cancel {
  font-family: IRANSans, sans-serif;
}
.swal2-container {
  z-index: 99999 !important;
}
.bg-white {
  background-color: #fff !important;
}
.MuiTable-root.MuiTableCell-body.MuiTableCell-alignLeft {
  font-family: IRANSans, sans-serif !important;
}

.MuiTable-root, .MuiGrid-root {
  font-family: IRANSans, sans-serif !important;
}

.MuiTable-root.MuiTableCell-body.MuiTableCell-alignCenter {
  font-family: IRANSans, sans-serif !important;
}

.MuiTable-root.MuiTableCell-body.MuiTableCell-alignCenter, input {
  font-family: IRANSans, sans-serif !important;
}

#root {
  height: 100%;
}

#basket .MuiTab-textColorInherit.Mui-selected {
  opacity: 1;
  background-color: #dddddd61;
}

.MuiCollapse-entered .MuiButtonBase-root .MuiSvgIcon-root {
  margin-left: 10px;
}

.ant-layout, .ant-menu, .ant-table-wrapper .ant-table {
  font-family: IRANSans, sans-serif !important;
}

.MuiFormLabel-root, .MuiTypography-root, .MuiButton-root {
  font-family: IRANSans, sans-serif !important;
}

.MuiSelect-icon {
  right: auto !important;
  left: 7px;
}
.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters {
  font-family: IRANSans, sans-serif !important;
}

a.profile div{
  // color: rgb(255, 255, 255) !important;
  color: #38291E !important;
}

.ant-layout-sider, .ant-layout-sider .ant-menu {
  background:#E2E8EF !important;
  color: #38291E;
}

a.profile:hover {
  background-color: #ffffff82;
  color: #38291E;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Semi-transparent gray background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Adjust the z-index as needed */
}
.loading-overlay .ant-spin {
  color: white;
}
.loading-overlay .ant-spin .ant-spin-dot-item {
  background-color: #fff;
}

.ant-menu-dark .ant-menu-submenu-selected >.ant-menu-submenu-title {
  color: rgba(188, 138, 101, 1);
  // background: rgba(188, 138, 101, 1);
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  color: #fff;
  background: rgba(188, 138, 101, 1);
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-submenu-title:hover {
  color: #fff;
  background-color: rgba(188, 138, 101, 1) !important;
}

.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active {
  color: #fff;
  background-color: rgba(188, 138, 101, 1) !important;
  background: rgba(188, 138, 101, 1);
}
.ant-menu-dark .ant-menu-item-selected {
  color: #fff;
  background-color: rgba(188, 138, 101, 1) !important;
  background: rgba(188, 138, 101, 1);
}
.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
  color: #fff;
  background-color: rgba(188, 138, 101, 1) !important;
  background: rgba(188, 138, 101, 1);
}

.custom-container {
  width: 100%;
}

.custom-container .rmdp-input {
  width: 100%;
  box-sizing: border-box;
  height: 100%;
}

.MuiList-root {
  max-height: 300px;
}

.ant-menu-dark .ant-menu-item, .ant-menu-dark>.ant-menu .ant-menu-item, .ant-menu-dark .ant-menu-submenu-title, .ant-menu-dark>.ant-menu .ant-menu-submenu-title {
  color: #38291e;
}
// .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-endAdornment {
//   margin-left: 0;
//   left: 10px
// }

.ck.ck-editor__editable_inline {
  direction: rtl;
  text-align: right;
}

.ant-statistic, .ant-statistic .ant-statistic-title {
  font-family: IRANSans, serif !important;
}