@import './fonts';

$font: 'IRANSans';
$font-light: 'IRANSans-light';
$font-bold: 'IRANSans-bold';
$regular-font-size: '0.9rem';
$primary-main-color: #0b2545;
$secondary-main-color: #16c086;
$main-hint-color: #bdbdbd;
$main-border-color: #e1e1e1;
$primary-text-color: #1d313b;
$secondary-text-color: #757575;
$light-white-grey-color: #f7f9fa;
$white-color: #ffffff;
$black-color: #000000;
$anchor-tag-color: #2da3c5;
$error-color: #df0000;
