//Importing IRANSans fonts
@font-face {
  font-family: IRANSans-bold;
  font-style: normal;
  font-weight: bold;
  src: url('../../fonts/iranSans/eot/IRANSansWeb(FaNum)_Bold.eot');
  src: url('../../fonts/iranSans/eot/IRANSansWeb(FaNum)_Bold.eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */ url('../../fonts/iranSans/woff2/IRANSansWeb(FaNum)_Bold.woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/ url('../../fonts/iranSans/woff/IRANSansWeb(FaNum)_Bold.woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('../../fonts/iranSans/ttf/IRANSansWeb(FaNum)_Bold.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans-light;
  font-style: normal;
  font-weight: 300;
  src: url('../../fonts/iranSans/eot/IRANSansWeb(FaNum)_Light.eot');
  src: url('../../fonts/iranSans/eot/IRANSansWeb(FaNum)_Light.eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */ url('../../fonts/iranSans/woff2/IRANSansWeb(FaNum)_Light.woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/ url('../../fonts/iranSans/woff/IRANSansWeb(FaNum)_Light.woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('../../fonts/iranSans/ttf/IRANSansWeb(FaNum)_Light.ttf') format('truetype');
}

@font-face {
  font-family: IRANSans;
  font-style: normal;
  font-weight: normal;
  src: url('../../fonts/iranSans/eot/IRANSansWeb(FaNum).eot');
  src: url('../../fonts/iranSans/eot/IRANSansWeb(FaNum).eot?#iefix') format('embedded-opentype'),
    /* IE6-8 */ url('../../fonts/iranSans/woff2/IRANSansWeb(FaNum).woff2') format('woff2'),
    /* FF39+,Chrome36+, Opera24+*/ url('../../fonts/iranSans/woff/IRANSansWeb(FaNum).woff') format('woff'),
    /* FF3.6+, IE9, Chrome6+, Saf5.1+*/ url('../../fonts/iranSans/ttf/IRANSansWeb(FaNum).ttf') format('truetype');
}

//end of importing IRANSans fonts

//Importing material table fonts
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/materialTable/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2') format('woff2');
}

//end of importing material table fonts
