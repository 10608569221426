@import '../generalStyles/variables';

.container {
  position: relative;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: inline-block;
  z-index: 0;
}

.plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 20px;
  z-index: 2;
  font-size: 1rem;
  color: $white-color;
  background-color: $primary-main-color;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  text-align: center;
}

.plus > svg {
  width: 100%;
  height: 100%;
}

.title {
  font-family: $font-bold;
  color: $white-color;
  width: 150px;
  height: 100%;
  position: absolute;
  display: inline-block;
  right: calc(100% - 10px);
  z-index: 1;
  transition: all 1s ease-in-out;
  text-align: center;
  border-radius: 10px 0 0 10px;
  overflow: hidden;
  pointer-events: none;
}

.title > span {
  background-color: $primary-main-color;
  position: absolute;
  right: -105%;
  top: 0;
  line-height: 20px;
  height: 100%;
  transition: 0.8s;
  padding: 0 30px 0 10px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-size: 10px;
  pointer-events: all;
}

.container > .plus:hover ~ .title > span,
.container > .title > span:hover {
  right: -10px;
}

@media only screen and (max-width: 767px) {
  .plus {
    font-size: 0.7rem;
  }

  .title {
    width: 140px;
  }
}
